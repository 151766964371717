import moment from 'moment';
import { isEqual } from 'lodash';

import type { Moment } from 'moment';
import type { ParsedQuery } from 'query-string';
import type { TimeEntryNode, TimeEntriesQueryQuery } from 'graphql/__generated__/graphql';
import type { TimeObject } from 'helpers/time';

import { DATE_DASH_FORMAT } from 'constants/dateFormats';
import {
  TIME_ENTRIES_FILTERS_VALUES,
  TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE,
  TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE,
  TIME_ENTRIES_FILTER_CREATED_LTE,
  TIME_ENTRIES_FILTER_CREATED_GTE,
  TIME_ENTRIES_FILTER_TAG,
  TIME_ENTRIES_FILTER_TAG_NULL,
  NO_TAGS_FILTER_ID,
  TIME_ENTRIES_NO_TASK_KEY,
  TIME_ENTRIES_TEMP_ID_PREFIX,
} from 'constants/timeEntries';

import { getInitialIntegerArrayValue } from 'helpers/filters';
import { getDateFormatted } from 'helpers/dates';
import { getHoursAndMinutesFromSeconds } from 'helpers/time';

export type TimeEntriesDateGroup = {
  date: Moment
  timeEntries: TimeEntryNode[]
  duration: TimeObject
};

export type TimeEntriesDateGroups = {
  [key: string]: TimeEntriesDateGroup
};

export type TimeEntriesEntityGroup = {
  [key: string]: TimeEntryNode[]
};

/**
 * Returns pagination data from time entries query
 * @param {TimeEntriesQueryQuery} data
 * @returns {object}
 */
export const getPaginationDataFromTimeEntriesQuery = (data: TimeEntriesQueryQuery) => {
  const { time_entries: dataTimeEntries } = data || {};
  const timeEntries = dataTimeEntries ? dataTimeEntries?.edges.map(item => item.node) : [];
  const endCursor = dataTimeEntries?.pageInfo?.endCursor ?? null;
  const hasNextPage = dataTimeEntries?.pageInfo?.hasNextPage ?? false;

  return { timeEntries, endCursor, hasNextPage };
};

/**
 * Returns the query object with only time entries filters
 * @param {ParsedQuery} queryObject
 * @returns {object}
 */
export const getTimeEntriesQueryObject = (query: ParsedQuery<string | boolean>) => (
  Object.keys(query).reduce((updatedQuery, key) => (
    TIME_ENTRIES_FILTERS_VALUES.includes(key)
      ? { ...updatedQuery, [key]: query[key] }
      : updatedQuery
  ), {})
);

/**
 * Returns whether or not time entries filters have been applied to the query object
 * @param {ParsedQuery} queryObject
 * @returns {boolean}
 */
export const hasAppliedTimeEntriesFilters = (queryObject: ParsedQuery) => (
  Object.keys(queryObject).some(key => TIME_ENTRIES_FILTERS_VALUES.includes(key))
);

/**
 * Gets the default due date boundary based on the found dates
 * Match the TIME_ENTRIES_DATE_BOUNDARIES constant
 * @param {ParsedQuery} filters
 * @param {string} type
 * @returns{string}
 */
export const getInitialTimeEntriesDateBoundary = (filters: ParsedQuery, type: 'referenceDate' | 'createdDate') => {
  let dateBoundary = 'is';
  let gteDate;
  let lteDate;

  if (type === 'referenceDate') {
    gteDate = filters?.[TIME_ENTRIES_FILTER_REFERENCE_DATE_GTE];
    lteDate = filters?.[TIME_ENTRIES_FILTER_REFERENCE_DATE_LTE];
  }
  if (type === 'createdDate') {
    gteDate = filters?.[TIME_ENTRIES_FILTER_CREATED_GTE];
    lteDate = filters?.[TIME_ENTRIES_FILTER_CREATED_LTE];
  }

  if (gteDate && lteDate) {
    dateBoundary = 'between';
  } else if (gteDate) {
    dateBoundary = 'after';
  } else if (lteDate) {
    dateBoundary = 'before';
  }

  return dateBoundary;
};

/**
 * Returns the tag value if found in the query object
 * Note that this also sets the initial tag value to our constants for
 * TIME_ENTRIES_FILTER_TAG_NULL in case that value is in the query obj
 * @param {ParsedQuery} query
 * @param {boolean} isMulti
 * @returns {number[] | number}
 */
export const getInitialTimeEntryTagValue = (query: ParsedQuery, isMulti = false) => {
  if (query && query[TIME_ENTRIES_FILTER_TAG_NULL]) {
    return NO_TAGS_FILTER_ID;
  }

  if (query && TIME_ENTRIES_FILTER_TAG in query) {
    return isMulti
      ? getInitialIntegerArrayValue(query, TIME_ENTRIES_FILTER_TAG)
      : parseInt(query[TIME_ENTRIES_FILTER_TAG], 10);
  }

  return isMulti ? [] : null;
};

/**
 * Returns given values based on the value of the tag field.
 * @param {number} value
 * @returns {object}
 */
export const getTimeEntryTagQueryFromSelectValue = (value: number) => {
  const tagNullValues = [NO_TAGS_FILTER_ID];

  return tagNullValues.includes(value)
    ? { [TIME_ENTRIES_FILTER_TAG_NULL]: value === NO_TAGS_FILTER_ID }
    : { [TIME_ENTRIES_FILTER_TAG]: value };
};

/**
 * Get total duration from a list of time entries in seconds
 * @param {TimeEntryNode} timeEntries
 * @returns {number}
 */
export const getTimeEntriesDurationInSeconds = (timeEntries: Partial<TimeEntryNode>[]) => (
  timeEntries.reduce((duration, timeEntry) => duration + (timeEntry?.inputted_duration || 0), 0)
);

/**
 * Get total duration from a list of time entries formatted in hours and minutes
 * @param {TimeEntryNode} timeEntries
 * @returns {TimeObject}
 */
export const getTimeEntriesDuration = (timeEntries: Partial<TimeEntryNode>[]) => {
  const durationInSeconds = getTimeEntriesDurationInSeconds(timeEntries);

  return getHoursAndMinutesFromSeconds(durationInSeconds);
};

/**
 * Gets an array of dates for the timesheet week
 * @param {Moment} date
 * @returns {Moment[]}
 */
export const getTimesheetWeek = (date: Moment) => Array.from({ length: 5 }, (_, index) => (
  date.clone().weekday(index + 1).startOf('day')
));

/**
 * Gets the desired reference date for our time tracked modal
 * @param {Moment[]} week
 * @returns {Moment}
 */
export const getReferenceDateFromWeek = (week: Moment[]) => {
  const firstDay = week[0];
  const today = moment();

  return firstDay.isSame(today, 'week') ? today : firstDay;
};

/**
 * Groups time entries by given dates
 * @param {TimeEntryNode[]} timeEntries
 * @param {Moment} dates
 * @returns {TimeEntriesDateGroups}
 */
export const groupTimeEntriesByDates = (timeEntries: TimeEntryNode[], dates: Moment[]) => {
  const defaultGroups: TimeEntriesDateGroups = dates.reduce((g, date) => (
    {
      ...g,
      [getDateFormatted(date, DATE_DASH_FORMAT)]: {
        date,
        timeEntries: [],
        duration: { hours: 0, minutes: 0 },
      },
    }
  ), {});

  return timeEntries.reduce((groups, timeEntry) => {
    const dateKey = getDateFormatted(timeEntry.reference_date, DATE_DASH_FORMAT);

    if (dateKey in groups) {
      const updatedTimeEntries = [...groups[dateKey].timeEntries, timeEntry];
      const duration = getTimeEntriesDuration(updatedTimeEntries);

      return {
        ...groups,
        [dateKey]: { ...groups[dateKey], timeEntries: updatedTimeEntries, duration },
      };
    }

    return groups;
  }, defaultGroups);
};

/**
 * Groups time entries by their project
 * @param timeEntries
 * @returns {TimeEntriesEntityGroup}
 */
export const groupTimeEntriesByProject = (timeEntries: TimeEntryNode[]) => (
  timeEntries.reduce((groups: TimeEntriesEntityGroup, timeEntry) => {
    const key = timeEntry.project?.id ?? 'no-project';

    if (key in groups) {
      const updatedTimeEntries = [...groups[key], timeEntry];

      return { ...groups, [key]: updatedTimeEntries };
    }

    return { ...groups, [key]: [timeEntry] };
  }, {})
);

/**
 * Groups time entries by their task
 * @param timeEntries
 * @returns {TimeEntriesEntityGroup}
 */
export const groupTimeEntriesByTask = (timeEntries: TimeEntryNode[]) => (
  timeEntries.reduce((groups: TimeEntriesEntityGroup, timeEntry) => {
    const key = timeEntry.task?.id ?? TIME_ENTRIES_NO_TASK_KEY;

    if (key in groups) {
      const updatedTimeEntries = [...groups[key], timeEntry];

      return { ...groups, [key]: updatedTimeEntries };
    }

    return { ...groups, [key]: [timeEntry] };
  }, {})
);

/**
 * Gets the timesheet time entry fields
 * @param entry
 * @param billable
 * @returns
 */
export const getTimesheetTimeEntryFields = (
  entry: TimeEntryNode = null,
  billable = false,
): Partial<TimeEntryNode> => ({
  tags: entry?.tags?.[0]?.id || null,
  inputted_duration: entry?.inputted_duration ?? null,
  billable: entry?.billable ?? billable,
  notes: entry?.notes || '',
  id: entry?.id || `${TIME_ENTRIES_TEMP_ID_PREFIX}${crypto.randomUUID()}`,
});

/**
 * Get time entry object list from a list of time entries for faster lookup.
 * @param timeEntries
 * @returns
 */
export const getTaskEntryObjects = (timeEntries: Partial<TimeEntryNode>[]) => timeEntries
  .reduce((obj, timeEntry) => ({ ...obj, [timeEntry.id]: timeEntry }), {});

/**
 * Returns a list of entry ids that the user has chosen to remove.
 * @param values
 * @param initialValues
 * @returns
 */
export const getTimeEntriesToRemove = (
  values: Partial<TimeEntryNode>[],
  initialValues: Partial<TimeEntryNode>[],
) => {
  const entries = getTaskEntryObjects(values);

  return initialValues.reduce((ids: string[], entry) => {
    const { id } = entry;
    const hasEntry = id in entries;
    const isNewEntry = typeof id === 'string' && id.startsWith(TIME_ENTRIES_TEMP_ID_PREFIX);

    return !hasEntry && !isNewEntry ? [...ids, id] : ids;
  }, []);
};

/**
 * Returns a list of entries that user has added/edited
 * @param newEntries
 * @param entries
 * @returns
 */
export const getTimeEntriesToAddAndToEdit = (
  values: Partial<TimeEntryNode>[],
  initialValues: Partial<TimeEntryNode>[],
) => {
  const entries = getTaskEntryObjects(initialValues);

  return values.reduce(([toAdd, toEdit]: Partial<TimeEntryNode>[][], entry) => {
    const isNewEntry = typeof entry.id === 'string' && entry.id.startsWith(TIME_ENTRIES_TEMP_ID_PREFIX);
    const hasEntry = !isNewEntry && entry.id in entries;

    if (hasEntry) {
      return isEqual(entry, entries[entry.id])
        ? [toAdd, toEdit]
        : [toAdd, [...toEdit, entry]];
    }

    return [[...toAdd, entry], toEdit];
  }, [[], []]);
};
