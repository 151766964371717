type SingleTimelineConfig = {
  id: string
  text: string
  unitType: 'day' | 'month'
  parentUnitType: 'month' | 'year'
  unitFormat: string,
  parentUnitFormat: string,
  dayWidth: number
};

type TimelineConfig = {
  week: SingleTimelineConfig
  month: SingleTimelineConfig
  quarter: SingleTimelineConfig
  halfYear: SingleTimelineConfig
  year: SingleTimelineConfig
};

type BaseTimelineCustomizationOption = {
  id: string
  title: string
};

type TimelineOutlineCustomizationOption = BaseTimelineCustomizationOption & {
  client: boolean
  template: boolean
};

type TimelineCustomizationOption = TimelineOutlineCustomizationOption & {
  testId: string
  description?: string
};

type BaseTimelineCustomizationOptions = BaseTimelineCustomizationOption[];
type TimelineOutlineCustomizationOptions = TimelineOutlineCustomizationOption[];
type TimelineCustomizationOptions = TimelineCustomizationOption[];

/**
 * Timeline config object determines constants for each of the views found in the timeline.
 *
 * @property {string} id - View identifier, must be the same as the object key
 * @property {string} text - Textual representation of each view.
 * @property {string} unitType - The main unit type being displayed in each view.
 * @property {string} parentUnitType - The parent unit type being displayed in each view.
 * @property {string} unitFormat - The date format for each unit.
 * @property {string} parentUnitFormat - The date format for each parent in the view.
 * @property {string} dayWidth - The width of a day in pixels for each view.
 *
 * Each object is in order of zooming level (most zoomed in to least)
 */
export const TIMELINE: TimelineConfig = {
  week: {
    id: 'week',
    text: 'Weeks',
    unitType: 'day',
    parentUnitType: 'month',
    unitFormat: 'dd D',
    parentUnitFormat: 'MMMM YYYY',
    dayWidth: 96,
  },
  month: {
    id: 'month',
    text: 'Months',
    unitType: 'day',
    parentUnitType: 'month',
    unitFormat: 'D',
    parentUnitFormat: 'MMMM YYYY',
    dayWidth: 24,
  },
  quarter: {
    id: 'quarter',
    text: 'Quarters',
    unitType: 'month',
    parentUnitType: 'year',
    unitFormat: 'MMM',
    parentUnitFormat: 'YYYY',
    dayWidth: 8,
  },
  halfYear: {
    id: 'halfYear',
    text: 'Half years',
    unitType: 'month',
    parentUnitType: 'year',
    unitFormat: 'MMM',
    parentUnitFormat: 'YYYY',
    dayWidth: 4,
  },
  year: {
    id: 'year',
    text: 'Years',
    unitType: 'month',
    parentUnitType: 'year',
    unitFormat: 'MMM',
    parentUnitFormat: 'YYYY',
    dayWidth: 2,
  },
};

export const TEMPLATE_TIMELINE_DAY_WIDTH = 24;
export const TIMELINE_OUTLINE_DEFAULT_WIDTH = 720;
export const TIMELINE_OUTLINE_TIME_COLUMN_WIDTH = 68;
export const TIMELINE_OUTLINE_DATE_COLUMN_WIDTH = 88;
export const TIMELINE_OUTLINE_START_DAY_WIDTH = 72;
export const TIMELINE_OUTLINE_DURATION_WIDTH = 72;
export const TIMELINE_OUTLINE_STATUS_COLUMN_WIDTH = 100;
export const TIMELINE_TASK_ROW_HEIGHT = 30;
export const TIMELINE_DEPENDENCY_CHILD_BUFFER = 24;
export const TIMELINE_DEPENDENCY_PARENT_BUFFER = 8;
export const TIMELINE_TASK_DRAG_PADDING = 100;
export const TIMELINE_TASK_ROW_CSS_CLASS = 'timeline-task'; // required to get hover styles working correctly
export const TIMELINE_PREVIEW_FOOTER_HEIGHT = 64;

// Task Customization keys
export const TIMELINE_CUSTOMIZATION_TASK_TITLES = 'showTaskTitles';
export const TIMELINE_CUSTOMIZATION_TASK_DEPENDENCIES = 'showTaskDependencies';
export const TIMELINE_CUSTOMIZATION_TASK_LATENESS = 'showTaskLateness';
export const TIMELINE_CUSTOMIZATION_TASK_BASELINE = 'showTaskBaseline';
export const TIMELINE_CUSTOMIZATION_TASK_ACTUAL_DATES = 'showTaskActualDates';

// Outline Customization keys
export const TIMELINE_CUSTOMIZATION_OUTLINE_DURATION = 'duration';
export const TIMELINE_CUSTOMIZATION_OUTLINE_START_DAY = 'startDay';
export const TIMELINE_CUSTOMIZATION_OUTLINE_DUE_DATE = 'dueDate';
export const TIMELINE_CUSTOMIZATION_OUTLINE_START_DATE = 'startDate';
export const TIMELINE_CUSTOMIZATION_OUTLINE_STATUS = 'status';
export const TIMELINE_CUSTOMIZATION_OUTLINE_BADGES = 'badges';
export const TIMELINE_CUSTOMIZATION_OUTLINE_ESTIMATED_TIME = 'estimatedTime';

/**
 * Config object for timeline customization options
 *
 * @property {TimelineCustomizationOptions[]} option
 * @property {string} option[].id - the key in redux to hide/show option
 * @property {string} option[].testId - friendly string for testing
 * @property {string} option[].title - human friendly string
 * @property {boolean} option[].client - Determines whether we should show option to client
 * @property {boolean} option[].template - Determines whether we should show option in template
 * @property {string} option[].description - Description of the option shown in tooltip
 */
export const TIMELINE_CUSTOMIZATION_OPTIONS: TimelineCustomizationOptions = [
  {
    id: TIMELINE_CUSTOMIZATION_TASK_DEPENDENCIES,
    testId: 'dependencies',
    title: 'dependencies',
    client: true,
    template: true,
  },
  {
    id: TIMELINE_CUSTOMIZATION_TASK_LATENESS,
    testId: 'task-lateness',
    title: 'task lateness',
    client: false,
    template: false,
  },
  {
    id: TIMELINE_CUSTOMIZATION_TASK_TITLES,
    testId: 'task-titles',
    title: 'task titles',
    client: true,
    template: true,
  },
  {
    id: TIMELINE_CUSTOMIZATION_TASK_BASELINE,
    testId: 'task-baseline',
    title: 'baseline dates',
    client: false,
    template: false,
    description: 'This option will display baseline dates of each task on the timeline. The baseline can be captured automatically or from project actions.',
  },
  {
    id: TIMELINE_CUSTOMIZATION_TASK_ACTUAL_DATES,
    testId: 'task-actual-dates',
    title: 'actual dates',
    client: true,
    template: false,
  },
];

/**
 * Config object for project timline customization outline options
 *
 * @property {TimelineOutlineCustomizationOption[]} option
 * @property {string} option[].id - the key in redux to hide/show option
 * @property {string} option[].title - human friendly string
 * @property {boolean} option[].client - Determines whether we should show option to client
 * @property {boolean} option[].template - Determines whether we should show option in template
 */
export const PROJECT_TIMELINE_CUSTOMIZATION_OUTLINE_OPTIONS: TimelineOutlineCustomizationOptions = [
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_BADGES,
    title: 'tags',
    template: true,
    client: true,
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_ESTIMATED_TIME,
    title: 'estimated time',
    template: true,
    client: false,
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_START_DATE,
    title: 'start date',
    template: true,
    client: true,
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_DUE_DATE,
    title: 'due date',
    template: true,
    client: true,
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_STATUS,
    title: 'status',
    template: false,
    client: true,
  },
];

/**
 * Config object for template timeline customization outline options
 *
 * @property {BaseTimelineCustomizationOption[]} option
 * @property {string} option[].id - the key in redux to hide/show option
 * @property {string} option[].title - human friendly string
 */
export const TEMPLATE_TIMELINE_CUSTOMIZATION_OUTLINE_OPTIONS: BaseTimelineCustomizationOptions = [
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_BADGES,
    title: 'tags',
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_ESTIMATED_TIME,
    title: 'estimated time',
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_START_DAY,
    title: 'start day',
  },
  {
    id: TIMELINE_CUSTOMIZATION_OUTLINE_DURATION,
    title: 'duration',
  },
];
