/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable default-param-last */
import moment from 'moment';

import type { ProjectNode, TaskNode } from 'graphql/__generated__/graphql';
import type { TimeEntriesDateGroup } from 'helpers/timeEntries';

import { getTimesheetWeek } from 'helpers/timeEntries';

import type { Reducer } from 'redux';
import type { Moment } from 'moment';
import type { ResourcesViewActions } from 'actions/ui/ResourcesView';

export type TimesheetFormProps = {
  project?: ProjectNode
  task?: TaskNode
  group?: TimeEntriesDateGroup
  cellId: string
};

type TimesheetFormState = {
  open: boolean
  props: TimesheetFormProps
};

type State = {
  timesheetWeek: Moment[]
  timesheetUserId: number | null
  timesheetForm: TimesheetFormState
  highlightedCell: string | null
};

const initialState = {
  timesheetWeek: getTimesheetWeek(moment()),
  timesheetUserId: null,
  timesheetForm: {
    open: false,
    props: {},
  },
  highlightedCell: null,
};

export enum ActionTypes {
  SET_TIMESHEET_WEEK = 'RESOURCES_VIEW_SET_TIMESHEET_WEEK',
  SET_TIMESHEET_USER_ID = 'RESOURCES_VIEW_SET_TIMESHEET_USER_ID',
  OPEN_TIMESHEET_FORM = 'RESOURCES_VIEW_OPEN_TIMESHEET_FORM',
  CLOSE_TIMESHEET_FORM = 'RESOURCES_VIEW_CLOSE_TIMESHEET_FORM',
  SET_HIGHLIGHTED_CELL = 'RESOURCES_VIEW_HIGHLIGHT_CELL',
}

const forms: Reducer<State, ResourcesViewActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TIMESHEET_WEEK:
      return { ...state, timesheetWeek: action.timesheetWeek };
    case ActionTypes.SET_TIMESHEET_USER_ID:
      return { ...state, timesheetUserId: action.id };
    case ActionTypes.OPEN_TIMESHEET_FORM:
      return { ...state, timesheetForm: { open: true, props: action.props } };
    case ActionTypes.CLOSE_TIMESHEET_FORM:
      return { ...state, timesheetForm: { open: false, props: {} } };
    case ActionTypes.SET_HIGHLIGHTED_CELL:
      return { ...state, highlightedCell: action.cellId };
    default:
      return state;
  }
};

export default forms;
